<script setup>
const { locale } = useI18n()
const switchLocalePath = useSwitchLocalePath()
const changeLocale = () => {
  reloadNuxtApp({
    path: switchLocalePath(locale.value),
  })
}
</script>
<template>
  <select v-model="$i18n.locale" aria-label="choose language" class="border-0 w-auto pl-0 text-xl bg-white" @change="changeLocale">
    <option v-for="item in $i18n.locales" :id="item.code" :key="`lang-${item.code}`" :value="item.code">
      {{ item.name }}
    </option>
  </select>
</template>
