import { default as _91model_93HphTp8JSuOMeta } from "/opt/buildhome/repo/pages/buy/[brand]/[model].vue?macro=true";
import { default as index3MtmtOIvRFMeta } from "/opt/buildhome/repo/pages/buy/[brand]/index.vue?macro=true";
import { default as index1sQ6jSalYQMeta } from "/opt/buildhome/repo/pages/buy/index.vue?macro=true";
import { default as indexh6zcUsGJi2Meta } from "/opt/buildhome/repo/pages/buy/metaxeirismena/index.vue?macro=true";
import { default as index2j9n8RhyylMeta } from "/opt/buildhome/repo/pages/contact/index.vue?macro=true";
import { default as indexdV12ScEXWEMeta } from "/opt/buildhome/repo/pages/contract-templates/index.vue?macro=true";
import { default as indexSAfi5BG1CrMeta } from "/opt/buildhome/repo/pages/cookies-policy/index.vue?macro=true";
import { default as service_45manualPE5J25tvXJMeta } from "/opt/buildhome/repo/pages/docs/service-manual.vue?macro=true";
import { default as indexqEJDqEYin1Meta } from "/opt/buildhome/repo/pages/drivehome/index.vue?macro=true";
import { default as indexzozFRTH6RXMeta } from "/opt/buildhome/repo/pages/drivehome/terms-of-service/index.vue?macro=true";
import { default as indexm95tzda2j7Meta } from "/opt/buildhome/repo/pages/faqs/index.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as _91model_932osQPfDxmWMeta } from "/opt/buildhome/repo/pages/instaride/[brand]/[model].vue?macro=true";
import { default as index5MtALKDfgfMeta } from "/opt/buildhome/repo/pages/instaride/[brand]/index.vue?macro=true";
import { default as indexzql3V2QwJmMeta } from "/opt/buildhome/repo/pages/instaride/index.vue?macro=true";
import { default as _91category_9388XJqVWJG8Meta } from "/opt/buildhome/repo/pages/instaride/listing/[category].vue?macro=true";
import { default as index02Yf7VtX0RMeta } from "/opt/buildhome/repo/pages/instaride/listing/index.vue?macro=true";
import { default as _91model_933TTNjXopcSMeta } from "/opt/buildhome/repo/pages/leasing/[brand]/[model].vue?macro=true";
import { default as indexN6yaP0afg5Meta } from "/opt/buildhome/repo/pages/leasing/[brand]/index.vue?macro=true";
import { default as _91model_936ALCNRYfjnMeta } from "/opt/buildhome/repo/pages/leasing/blueground/[type]/[brand]/[model].vue?macro=true";
import { default as index3fM5UvgobmMeta } from "/opt/buildhome/repo/pages/leasing/blueground/[type]/[brand]/index.vue?macro=true";
import { default as indexRA1M7CnfDZMeta } from "/opt/buildhome/repo/pages/leasing/blueground/[type]/index.vue?macro=true";
import { default as indexNLR9BwTrjIMeta } from "/opt/buildhome/repo/pages/leasing/blueground/index.vue?macro=true";
import { default as _91model_93WMXN6wL56lMeta } from "/opt/buildhome/repo/pages/leasing/cu/[type]/[brand]/[model].vue?macro=true";
import { default as indexZSWox4YJDJMeta } from "/opt/buildhome/repo/pages/leasing/cu/[type]/[brand]/index.vue?macro=true";
import { default as indexXUQa0ORYKKMeta } from "/opt/buildhome/repo/pages/leasing/cu/[type]/index.vue?macro=true";
import { default as indexlbTkh8g04GMeta } from "/opt/buildhome/repo/pages/leasing/cu/index.vue?macro=true";
import { default as _91model_93JpzVFVfojtMeta } from "/opt/buildhome/repo/pages/leasing/heron/[type]/[brand]/[model].vue?macro=true";
import { default as index8DMAOO5noNMeta } from "/opt/buildhome/repo/pages/leasing/heron/[type]/[brand]/index.vue?macro=true";
import { default as indexx7B3qscw1vMeta } from "/opt/buildhome/repo/pages/leasing/heron/[type]/index.vue?macro=true";
import { default as indexnxPcrzNyQXMeta } from "/opt/buildhome/repo/pages/leasing/heron/index.vue?macro=true";
import { default as indexThHo8ohnCnMeta } from "/opt/buildhome/repo/pages/leasing/index.vue?macro=true";
import { default as automatopJe0syoL1DMeta } from "/opt/buildhome/repo/pages/leasing/metaxeirismena/automato.vue?macro=true";
import { default as epaggelmatikanBZ68ob2kbMeta } from "/opt/buildhome/repo/pages/leasing/metaxeirismena/epaggelmatika.vue?macro=true";
import { default as fysiko_45aerioBA8TI1EvzZMeta } from "/opt/buildhome/repo/pages/leasing/metaxeirismena/fysiko-aerio.vue?macro=true";
import { default as idiotes6wh31K49XqMeta } from "/opt/buildhome/repo/pages/leasing/metaxeirismena/idiotes.vue?macro=true";
import { default as ilektrika_45uvridikaJl2bRD3WpJMeta } from "/opt/buildhome/repo/pages/leasing/metaxeirismena/ilektrika-uvridika.vue?macro=true";
import { default as indexglBvi7WwbkMeta } from "/opt/buildhome/repo/pages/leasing/metaxeirismena/index.vue?macro=true";
import { default as megalaLxSkpM5TLyMeta } from "/opt/buildhome/repo/pages/leasing/metaxeirismena/megala.vue?macro=true";
import { default as mesaiawOUZbnS8oyMeta } from "/opt/buildhome/repo/pages/leasing/metaxeirismena/mesaia.vue?macro=true";
import { default as mikraKJg5Hhh1MoMeta } from "/opt/buildhome/repo/pages/leasing/metaxeirismena/mikra.vue?macro=true";
import { default as petrelaiofKJ3tccT20Meta } from "/opt/buildhome/repo/pages/leasing/metaxeirismena/petrelaio.vue?macro=true";
import { default as prosforesNoYY0mASPpMeta } from "/opt/buildhome/repo/pages/leasing/metaxeirismena/prosfores.vue?macro=true";
import { default as ugraeriobR7nLd3SvIMeta } from "/opt/buildhome/repo/pages/leasing/metaxeirismena/ugraerio.vue?macro=true";
import { default as venziniFY7nl49LMmMeta } from "/opt/buildhome/repo/pages/leasing/metaxeirismena/venzini.vue?macro=true";
import { default as xeirokinitovEq5XdkIOHMeta } from "/opt/buildhome/repo/pages/leasing/metaxeirismena/xeirokinito.vue?macro=true";
import { default as _91partner_93WusCb9nUoKMeta } from "/opt/buildhome/repo/pages/our-partners/[partner].vue?macro=true";
import { default as indexdG3Cp8r0QaMeta } from "/opt/buildhome/repo/pages/our-partners/index.vue?macro=true";
import { default as indexzgyhDcmrmzMeta } from "/opt/buildhome/repo/pages/privacy/index.vue?macro=true";
import { default as index8kMIv7CV2kMeta } from "/opt/buildhome/repo/pages/terms-of-service/index.vue?macro=true";
import { default as component_45stubGZr1UkTkg3Meta } from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubGZr1UkTkg3 } from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "buy-brand-model___el",
    path: "/buy/:brand()/:model()",
    component: () => import("/opt/buildhome/repo/pages/buy/[brand]/[model].vue")
  },
  {
    name: "buy-brand-model___en",
    path: "/en/buy/:brand()/:model()",
    component: () => import("/opt/buildhome/repo/pages/buy/[brand]/[model].vue")
  },
  {
    name: "buy-brand___el",
    path: "/buy/:brand()",
    component: () => import("/opt/buildhome/repo/pages/buy/[brand]/index.vue")
  },
  {
    name: "buy-brand___en",
    path: "/en/buy/:brand()",
    component: () => import("/opt/buildhome/repo/pages/buy/[brand]/index.vue")
  },
  {
    name: "buy___el",
    path: "/buy",
    component: () => import("/opt/buildhome/repo/pages/buy/index.vue")
  },
  {
    name: "buy___en",
    path: "/en/buy",
    component: () => import("/opt/buildhome/repo/pages/buy/index.vue")
  },
  {
    name: "buy-metaxeirismena___el",
    path: "/buy/metaxeirismena",
    component: () => import("/opt/buildhome/repo/pages/buy/metaxeirismena/index.vue")
  },
  {
    name: "buy-metaxeirismena___en",
    path: "/en/buy/used-cars",
    component: () => import("/opt/buildhome/repo/pages/buy/metaxeirismena/index.vue")
  },
  {
    name: "contact___el",
    path: "/contact",
    component: () => import("/opt/buildhome/repo/pages/contact/index.vue")
  },
  {
    name: "contact___en",
    path: "/en/contact",
    component: () => import("/opt/buildhome/repo/pages/contact/index.vue")
  },
  {
    name: "contract-templates___el",
    path: "/contract-templates",
    component: () => import("/opt/buildhome/repo/pages/contract-templates/index.vue")
  },
  {
    name: "contract-templates___en",
    path: "/en/contract-templates",
    component: () => import("/opt/buildhome/repo/pages/contract-templates/index.vue")
  },
  {
    name: "cookies-policy___el",
    path: "/cookies-policy",
    component: () => import("/opt/buildhome/repo/pages/cookies-policy/index.vue")
  },
  {
    name: "cookies-policy___en",
    path: "/en/cookies-policy",
    component: () => import("/opt/buildhome/repo/pages/cookies-policy/index.vue")
  },
  {
    name: "docs-service-manual___el",
    path: "/docs/service-manual",
    component: () => import("/opt/buildhome/repo/pages/docs/service-manual.vue")
  },
  {
    name: "docs-service-manual___en",
    path: "/en/docs/service-manual",
    component: () => import("/opt/buildhome/repo/pages/docs/service-manual.vue")
  },
  {
    name: "drivehome___el",
    path: "/drivehome",
    meta: indexqEJDqEYin1Meta || {},
    component: () => import("/opt/buildhome/repo/pages/drivehome/index.vue")
  },
  {
    name: "drivehome___en",
    path: "/en/drivehome",
    meta: indexqEJDqEYin1Meta || {},
    component: () => import("/opt/buildhome/repo/pages/drivehome/index.vue")
  },
  {
    name: "drivehome-terms-of-service___el",
    path: "/drivehome/terms-of-service",
    component: () => import("/opt/buildhome/repo/pages/drivehome/terms-of-service/index.vue")
  },
  {
    name: "drivehome-terms-of-service___en",
    path: "/en/drivehome/terms-of-service",
    component: () => import("/opt/buildhome/repo/pages/drivehome/terms-of-service/index.vue")
  },
  {
    name: "faqs___el",
    path: "/faqs",
    component: () => import("/opt/buildhome/repo/pages/faqs/index.vue")
  },
  {
    name: "faqs___en",
    path: "/en/faqs",
    component: () => import("/opt/buildhome/repo/pages/faqs/index.vue")
  },
  {
    name: "index___el",
    path: "/",
    component: () => import("/opt/buildhome/repo/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/opt/buildhome/repo/pages/index.vue")
  },
  {
    name: "instaride-brand-model___el",
    path: "/instaride/:brand()/:model()",
    meta: _91model_932osQPfDxmWMeta || {},
    component: () => import("/opt/buildhome/repo/pages/instaride/[brand]/[model].vue")
  },
  {
    name: "instaride-brand-model___en",
    path: "/en/instaride/:brand()/:model()",
    meta: _91model_932osQPfDxmWMeta || {},
    component: () => import("/opt/buildhome/repo/pages/instaride/[brand]/[model].vue")
  },
  {
    name: "instaride-brand___el",
    path: "/instaride/:brand()",
    meta: index5MtALKDfgfMeta || {},
    component: () => import("/opt/buildhome/repo/pages/instaride/[brand]/index.vue")
  },
  {
    name: "instaride-brand___en",
    path: "/en/instaride/:brand()",
    meta: index5MtALKDfgfMeta || {},
    component: () => import("/opt/buildhome/repo/pages/instaride/[brand]/index.vue")
  },
  {
    name: "instaride___el",
    path: "/instaride",
    meta: indexzql3V2QwJmMeta || {},
    component: () => import("/opt/buildhome/repo/pages/instaride/index.vue")
  },
  {
    name: "instaride___en",
    path: "/en/instaride",
    meta: indexzql3V2QwJmMeta || {},
    component: () => import("/opt/buildhome/repo/pages/instaride/index.vue")
  },
  {
    name: "instaride-listing-category___el",
    path: "/instaride/listing/:category()",
    meta: _91category_9388XJqVWJG8Meta || {},
    component: () => import("/opt/buildhome/repo/pages/instaride/listing/[category].vue")
  },
  {
    name: "instaride-listing-category___en",
    path: "/en/instaride/listing/:category()",
    meta: _91category_9388XJqVWJG8Meta || {},
    component: () => import("/opt/buildhome/repo/pages/instaride/listing/[category].vue")
  },
  {
    name: "instaride-listing___el",
    path: "/instaride/listing",
    meta: index02Yf7VtX0RMeta || {},
    component: () => import("/opt/buildhome/repo/pages/instaride/listing/index.vue")
  },
  {
    name: "instaride-listing___en",
    path: "/en/instaride/listing",
    meta: index02Yf7VtX0RMeta || {},
    component: () => import("/opt/buildhome/repo/pages/instaride/listing/index.vue")
  },
  {
    name: "leasing-brand-model___el",
    path: "/leasing/:brand()/:model()",
    component: () => import("/opt/buildhome/repo/pages/leasing/[brand]/[model].vue")
  },
  {
    name: "leasing-brand-model___en",
    path: "/en/leasing/:brand()/:model()",
    component: () => import("/opt/buildhome/repo/pages/leasing/[brand]/[model].vue")
  },
  {
    name: "leasing-brand___el",
    path: "/leasing/:brand()",
    component: () => import("/opt/buildhome/repo/pages/leasing/[brand]/index.vue")
  },
  {
    name: "leasing-brand___en",
    path: "/en/leasing/:brand()",
    component: () => import("/opt/buildhome/repo/pages/leasing/[brand]/index.vue")
  },
  {
    name: "leasing-blueground-type-brand-model___el",
    path: "/leasing/blueground/:type()/:brand()/:model()",
    component: () => import("/opt/buildhome/repo/pages/leasing/blueground/[type]/[brand]/[model].vue")
  },
  {
    name: "leasing-blueground-type-brand-model___en",
    path: "/en/leasing/blueground/:type()/:brand()/:model()",
    component: () => import("/opt/buildhome/repo/pages/leasing/blueground/[type]/[brand]/[model].vue")
  },
  {
    name: "leasing-blueground-type-brand___el",
    path: "/leasing/blueground/:type()/:brand()",
    component: () => import("/opt/buildhome/repo/pages/leasing/blueground/[type]/[brand]/index.vue")
  },
  {
    name: "leasing-blueground-type-brand___en",
    path: "/en/leasing/blueground/:type()/:brand()",
    component: () => import("/opt/buildhome/repo/pages/leasing/blueground/[type]/[brand]/index.vue")
  },
  {
    name: "leasing-blueground-type___el",
    path: "/leasing/blueground/:type()",
    component: () => import("/opt/buildhome/repo/pages/leasing/blueground/[type]/index.vue")
  },
  {
    name: "leasing-blueground-type___en",
    path: "/en/leasing/blueground/:type()",
    component: () => import("/opt/buildhome/repo/pages/leasing/blueground/[type]/index.vue")
  },
  {
    name: "leasing-blueground___el",
    path: "/leasing/blueground",
    component: () => import("/opt/buildhome/repo/pages/leasing/blueground/index.vue")
  },
  {
    name: "leasing-blueground___en",
    path: "/en/leasing/blueground",
    component: () => import("/opt/buildhome/repo/pages/leasing/blueground/index.vue")
  },
  {
    name: "leasing-cu-type-brand-model___el",
    path: "/leasing/cu/:type()/:brand()/:model()",
    component: () => import("/opt/buildhome/repo/pages/leasing/cu/[type]/[brand]/[model].vue")
  },
  {
    name: "leasing-cu-type-brand-model___en",
    path: "/en/leasing/cu/:type()/:brand()/:model()",
    component: () => import("/opt/buildhome/repo/pages/leasing/cu/[type]/[brand]/[model].vue")
  },
  {
    name: "leasing-cu-type-brand___el",
    path: "/leasing/cu/:type()/:brand()",
    component: () => import("/opt/buildhome/repo/pages/leasing/cu/[type]/[brand]/index.vue")
  },
  {
    name: "leasing-cu-type-brand___en",
    path: "/en/leasing/cu/:type()/:brand()",
    component: () => import("/opt/buildhome/repo/pages/leasing/cu/[type]/[brand]/index.vue")
  },
  {
    name: "leasing-cu-type___el",
    path: "/leasing/cu/:type()",
    component: () => import("/opt/buildhome/repo/pages/leasing/cu/[type]/index.vue")
  },
  {
    name: "leasing-cu-type___en",
    path: "/en/leasing/cu/:type()",
    component: () => import("/opt/buildhome/repo/pages/leasing/cu/[type]/index.vue")
  },
  {
    name: "leasing-cu___el",
    path: "/leasing/cu",
    component: () => import("/opt/buildhome/repo/pages/leasing/cu/index.vue")
  },
  {
    name: "leasing-cu___en",
    path: "/en/leasing/cu",
    component: () => import("/opt/buildhome/repo/pages/leasing/cu/index.vue")
  },
  {
    name: "leasing-heron-type-brand-model___el",
    path: "/leasing/heron/:type()/:brand()/:model()",
    component: () => import("/opt/buildhome/repo/pages/leasing/heron/[type]/[brand]/[model].vue")
  },
  {
    name: "leasing-heron-type-brand-model___en",
    path: "/en/leasing/heron/:type()/:brand()/:model()",
    component: () => import("/opt/buildhome/repo/pages/leasing/heron/[type]/[brand]/[model].vue")
  },
  {
    name: "leasing-heron-type-brand___el",
    path: "/leasing/heron/:type()/:brand()",
    component: () => import("/opt/buildhome/repo/pages/leasing/heron/[type]/[brand]/index.vue")
  },
  {
    name: "leasing-heron-type-brand___en",
    path: "/en/leasing/heron/:type()/:brand()",
    component: () => import("/opt/buildhome/repo/pages/leasing/heron/[type]/[brand]/index.vue")
  },
  {
    name: "leasing-heron-type___el",
    path: "/leasing/heron/:type()",
    component: () => import("/opt/buildhome/repo/pages/leasing/heron/[type]/index.vue")
  },
  {
    name: "leasing-heron-type___en",
    path: "/en/leasing/heron/:type()",
    component: () => import("/opt/buildhome/repo/pages/leasing/heron/[type]/index.vue")
  },
  {
    name: "leasing-heron___el",
    path: "/leasing/heron",
    component: () => import("/opt/buildhome/repo/pages/leasing/heron/index.vue")
  },
  {
    name: "leasing-heron___en",
    path: "/en/leasing/heron",
    component: () => import("/opt/buildhome/repo/pages/leasing/heron/index.vue")
  },
  {
    name: "leasing___el",
    path: "/leasing",
    component: () => import("/opt/buildhome/repo/pages/leasing/index.vue")
  },
  {
    name: "leasing___en",
    path: "/en/leasing",
    component: () => import("/opt/buildhome/repo/pages/leasing/index.vue")
  },
  {
    name: "leasing-metaxeirismena-automato___el",
    path: "/leasing/metaxeirismena/automato",
    component: () => import("/opt/buildhome/repo/pages/leasing/metaxeirismena/automato.vue")
  },
  {
    name: "leasing-metaxeirismena-automato___en",
    path: "/en/leasing/cars/automatic",
    component: () => import("/opt/buildhome/repo/pages/leasing/metaxeirismena/automato.vue")
  },
  {
    name: "leasing-metaxeirismena-epaggelmatika___el",
    path: "/leasing/metaxeirismena/epaggelmatika",
    component: () => import("/opt/buildhome/repo/pages/leasing/metaxeirismena/epaggelmatika.vue")
  },
  {
    name: "leasing-metaxeirismena-epaggelmatika___en",
    path: "/en/leasing/cars/commercial-vehicles",
    component: () => import("/opt/buildhome/repo/pages/leasing/metaxeirismena/epaggelmatika.vue")
  },
  {
    name: "leasing-metaxeirismena-fysiko-aerio___el",
    path: "/leasing/metaxeirismena/fysiko-aerio",
    component: () => import("/opt/buildhome/repo/pages/leasing/metaxeirismena/fysiko-aerio.vue")
  },
  {
    name: "leasing-metaxeirismena-fysiko-aerio___en",
    path: "/en/leasing/cars/natural-gas",
    component: () => import("/opt/buildhome/repo/pages/leasing/metaxeirismena/fysiko-aerio.vue")
  },
  {
    name: "leasing-metaxeirismena-idiotes___el",
    path: "/leasing/metaxeirismena/idiotes",
    component: () => import("/opt/buildhome/repo/pages/leasing/metaxeirismena/idiotes.vue")
  },
  {
    name: "leasing-metaxeirismena-idiotes___en",
    path: "/en/leasing/cars/individuals",
    component: () => import("/opt/buildhome/repo/pages/leasing/metaxeirismena/idiotes.vue")
  },
  {
    name: "leasing-metaxeirismena-ilektrika-uvridika___el",
    path: "/leasing/metaxeirismena/ilektrika-uvridika",
    component: () => import("/opt/buildhome/repo/pages/leasing/metaxeirismena/ilektrika-uvridika.vue")
  },
  {
    name: "leasing-metaxeirismena-ilektrika-uvridika___en",
    path: "/en/leasing/cars/electric-hybrid",
    component: () => import("/opt/buildhome/repo/pages/leasing/metaxeirismena/ilektrika-uvridika.vue")
  },
  {
    name: "leasing-metaxeirismena___el",
    path: "/leasing/metaxeirismena",
    component: () => import("/opt/buildhome/repo/pages/leasing/metaxeirismena/index.vue")
  },
  {
    name: "leasing-metaxeirismena___en",
    path: "/en/leasing/cars",
    component: () => import("/opt/buildhome/repo/pages/leasing/metaxeirismena/index.vue")
  },
  {
    name: "leasing-metaxeirismena-megala___el",
    path: "/leasing/metaxeirismena/megala",
    component: () => import("/opt/buildhome/repo/pages/leasing/metaxeirismena/megala.vue")
  },
  {
    name: "leasing-metaxeirismena-megala___en",
    path: "/en/leasing/cars/large",
    component: () => import("/opt/buildhome/repo/pages/leasing/metaxeirismena/megala.vue")
  },
  {
    name: "leasing-metaxeirismena-mesaia___el",
    path: "/leasing/metaxeirismena/mesaia",
    component: () => import("/opt/buildhome/repo/pages/leasing/metaxeirismena/mesaia.vue")
  },
  {
    name: "leasing-metaxeirismena-mesaia___en",
    path: "/en/leasing/cars/mid-size",
    component: () => import("/opt/buildhome/repo/pages/leasing/metaxeirismena/mesaia.vue")
  },
  {
    name: "leasing-metaxeirismena-mikra___el",
    path: "/leasing/metaxeirismena/mikra",
    component: () => import("/opt/buildhome/repo/pages/leasing/metaxeirismena/mikra.vue")
  },
  {
    name: "leasing-metaxeirismena-mikra___en",
    path: "/en/leasing/cars/small",
    component: () => import("/opt/buildhome/repo/pages/leasing/metaxeirismena/mikra.vue")
  },
  {
    name: "leasing-metaxeirismena-petrelaio___el",
    path: "/leasing/metaxeirismena/petrelaio",
    component: () => import("/opt/buildhome/repo/pages/leasing/metaxeirismena/petrelaio.vue")
  },
  {
    name: "leasing-metaxeirismena-petrelaio___en",
    path: "/en/leasing/cars/diesel",
    component: () => import("/opt/buildhome/repo/pages/leasing/metaxeirismena/petrelaio.vue")
  },
  {
    name: "leasing-metaxeirismena-prosfores___el",
    path: "/leasing/metaxeirismena/prosfores",
    component: () => import("/opt/buildhome/repo/pages/leasing/metaxeirismena/prosfores.vue")
  },
  {
    name: "leasing-metaxeirismena-prosfores___en",
    path: "/en/leasing/cars/special-offers",
    component: () => import("/opt/buildhome/repo/pages/leasing/metaxeirismena/prosfores.vue")
  },
  {
    name: "leasing-metaxeirismena-ugraerio___el",
    path: "/leasing/metaxeirismena/ugraerio",
    component: () => import("/opt/buildhome/repo/pages/leasing/metaxeirismena/ugraerio.vue")
  },
  {
    name: "leasing-metaxeirismena-ugraerio___en",
    path: "/en/leasing/cars/lpg",
    component: () => import("/opt/buildhome/repo/pages/leasing/metaxeirismena/ugraerio.vue")
  },
  {
    name: "leasing-metaxeirismena-venzini___el",
    path: "/leasing/metaxeirismena/venzini",
    component: () => import("/opt/buildhome/repo/pages/leasing/metaxeirismena/venzini.vue")
  },
  {
    name: "leasing-metaxeirismena-venzini___en",
    path: "/en/leasing/cars/gasoline",
    component: () => import("/opt/buildhome/repo/pages/leasing/metaxeirismena/venzini.vue")
  },
  {
    name: "leasing-metaxeirismena-xeirokinito___el",
    path: "/leasing/metaxeirismena/xeirokinito",
    component: () => import("/opt/buildhome/repo/pages/leasing/metaxeirismena/xeirokinito.vue")
  },
  {
    name: "leasing-metaxeirismena-xeirokinito___en",
    path: "/en/leasing/cars/manual",
    component: () => import("/opt/buildhome/repo/pages/leasing/metaxeirismena/xeirokinito.vue")
  },
  {
    name: "our-partners-partner___el",
    path: "/our-partners/:partner()",
    component: () => import("/opt/buildhome/repo/pages/our-partners/[partner].vue")
  },
  {
    name: "our-partners-partner___en",
    path: "/en/our-partners/:partner()",
    component: () => import("/opt/buildhome/repo/pages/our-partners/[partner].vue")
  },
  {
    name: "our-partners___el",
    path: "/our-partners",
    component: () => import("/opt/buildhome/repo/pages/our-partners/index.vue")
  },
  {
    name: "our-partners___en",
    path: "/en/our-partners",
    component: () => import("/opt/buildhome/repo/pages/our-partners/index.vue")
  },
  {
    name: "privacy___el",
    path: "/privacy",
    component: () => import("/opt/buildhome/repo/pages/privacy/index.vue")
  },
  {
    name: "privacy___en",
    path: "/en/privacy",
    component: () => import("/opt/buildhome/repo/pages/privacy/index.vue")
  },
  {
    name: "terms-of-service___el",
    path: "/terms-of-service",
    component: () => import("/opt/buildhome/repo/pages/terms-of-service/index.vue")
  },
  {
    name: "terms-of-service___en",
    path: "/en/terms-of-service",
    component: () => import("/opt/buildhome/repo/pages/terms-of-service/index.vue")
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/contact-us",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/faq",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/listing",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/metaxeirismena",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/instride",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/instride/specialized",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/instride/aike",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/instride/seat",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/kotsovolos",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/el/leasing",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/metaxeirismena/instacarx",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/ebikes/kosmoride",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/metaxeirismena/ioannidis-bros",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/metaxeirismena/nikitheoharakis",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/metaxeirismena/auto-besikos",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/metaxeirismena/maxx-motors-aebe",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/metaxeirismena/stock-center-belmar",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/metaxeirismena/fms",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/metaxeirismena/autoone-syggelidis-group",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/metaxeirismena/hyundai",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/metaxeirismena/ford",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/metaxeirismena/toyota",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/metaxeirismena/tesla",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/metaxeirismena/skoda",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/metaxeirismena/bmw",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/metaxeirismena/volkswagen",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/metaxeirismena/audi",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/metaxeirismena/opel",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/metaxeirismena/kia",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/metaxeirismena/mercedes",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/metaxeirismena/alfa-romeo",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/metaxeirismena/peugeot",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/metaxeirismena/citroen",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/metaxeirismena/suzuki",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/metaxeirismena/fiat",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/metaxeirismena/nissan",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/jagua/e-pace",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/jeep/jeep-renegade",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/fiat/fiat-panda",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/rad-power-bikes/radrunner-plus",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/instaride/rad-power-bikes/radrunner?skuId=ICB00043",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/en/instaride/rad-power-bikes/radrunner?skuId=ICB00043",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/alfa-romeo/alfa-romeo-tonale-super-2022-mild-hybrid-327",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/alfa-romeo/alfa-romeo-tonale-super-diesel-356",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/audi/audi-a1-sportback-advanced-227",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/audi/audi-a3-auto-s-tronic-sedan-18-118",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/metaxeirismena/audi-a3-auto-s-tronic-sedan-18-118",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/audi/audi-a3-sb-tsi-20-64",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/audi/audi-a3-sportback-228",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/audi/audi-a4-auto-s-tronic-18-119",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/audi/audi-q2-comfort-30-tfsi-240",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/audi/audi-q2-tdi-auto-191",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/audi/audi-q3-advanced-35-tfsi-hybrid-s-tronic-77",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/audi/audi-q5-hybrid-288",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/bmw/bmw-i3-162",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/bmw/bmw-x1-sdrive-46",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/bmw/bmw-x2-sdrive-292",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/bmw/bmx-x1-14-46",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/bmw/bmx-x1-sdrive-46",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/carver/carver-s-313",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/citroen/berlingo-van-feel-187",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/citroen/berlingo-van-live-m-187",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/citroen/citroen-ami-electric-184",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/citroen/citroen-berlingo-van-feel-xl-186",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/citroen/citroen-c1-auto-18-75",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/citroen/citroen-c1-feel-16-23",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/metaxeirismena/citroen-c1-feel-16-23",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/citroen/citroen-c3-18-50",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/citroen/citroen-c3-corporate-19-98",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/metaxeirismena/citroen-c3-corporate-19-98",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/citroen/citroen-c3-corporate-98",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/citroen/citroen-c3-corporate-diesel-122",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/citroen/citroen-c3-flair-2022-360",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/citroen/citroen-c3-flair-98",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/citroen/citroen-c3-live-18-40",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/citroen/citroen-c3-live-18-51",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/citroen/citroen-c4-cactus-diesel-19-106",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/citroen/citroen-c4-shine-306",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/cupra/cupra-formentor-185",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/dacia/dacia-duster-expression-2022-329",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/dacia/dacia-duster-expression-4x2-329",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/dacia/dacia-duster-expression-4x4-329",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/dacia/dacia-jogger-comfort-lpg-7-seater-352",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/dacia/dacia-sandero-stepway-2022-330",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/dacia/dacia-sandero-stepway-style-355",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/ds/ds3-crossback-12-pure-tech-be-chic-260",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/ds/ds3-crossback-business-208",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/ds/ds4-business-2022-342",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/ds/ds7-business-218",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/fiat/doblo-cargo-maxi-l2h1-2022-324",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/fiat/fiat-500-club-310",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/fiat/fiat-500-connect-2022-326",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/fiat/fiat-500-dolce-vita-309",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/fiat/fiat-500-hybrid-connect-73",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/fiat/fiat-500-hybrid-cult-73",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/fiat/fiat-500-icon-cabrio-232",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/fiat/fiat-500-pop-19-71",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/fiat/fiat-500-pop-19-74",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/metaxeirismena/fiat-500-pop-19-74",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/fiat/fiat-500e-icon-cabrio-232",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/fiat/fiat-500x-17-108",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/fiat/fiat-doblo-cargo-serie-2-285",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/fiat/fiat-fiorino-van-284",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/fiat/fiat-panda-17-1",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/metaxeirismena/fiat-panda-17-1",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/fiat/fiat-panda-city-cross-307",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/fiat/fiat-panda-easy-19-19",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/fiat/fiat-panda-my21-city-life-283",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/fiat/fiat-panda-street-2022-308",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/fiat/fiat-panda-van-hybrid-275",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/fiat/fiat-punto-lounge-17-5",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/fiat/fiat-punto-lounge-b-17-28",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/fiat/fiat-tipo-sedan-17-26",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/fiat/neo-fiat-500-hybrid-connect-73",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/metaxeirismena/neo-fiat-500-hybrid-connect-73",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/fiat/neo-fiat-500-hybrid-cult-73",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/ford/ford-kuga-diesel-titanium-x-289",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/ford/ford-kuga-titanium-x-289",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/ford/ford-ranger-wildtrak-2022-363",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/ford/ford-transit-connect-trend-l1-344",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/ford/ford-transit-courier-trend-2022-322",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/hyundai/hyundai-bayon-234",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/hyundai/hyundai-bayon-exclusive-299",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/hyundai/hyundai-bayon-hybrid-premium-263",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/hyundai/hyundai-bayon-premium-2022-mild-hybrid-357",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/hyundai/hyundai-bayon-premium-234",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/hyundai/hyundai-bayon-premium-263",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/hyundai/hyundai-bayon-premium-hybrid-234",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/hyundai/hyundai-bayon-premium-hybrid-263",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/hyundai/hyundai-bayon-smart-234",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/hyundai/hyundai-i10-active-19-72",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/hyundai/hyundai-i20-classic-plus-241",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/hyundai/hyundai-i20-fresh-298",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/hyundai/hyundai-i20-premium-298",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/hyundai/hyundai-i30-39",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/hyundai/hyundai-i30-39-hertz",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/hyundai/hyundai-i30-48v-hybrid-premium-261",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/hyundai/hyundai-i30-premium-226",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/hyundai/hyundai-i30-premium-262",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/hyundai/hyundai-kona-180",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/hyundai/hyundai-kona-premium-180",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/hyundai/hyundai-kona-premium-206",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/hyundai/hyundai-kona-premium-auto-229",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/jaguar/jaguar-e-pace-4x4-160",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/jaguar/jaguar-f-pace-awd-4x4-159",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/jeep/jeep-compass-limited-hybrid-323",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/jeep/jeep-compass-limited-plug-hybrid-323",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/jeep/jeep-gladiator-overland-331",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/jeep/jeep-renegade-4x4-2",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/jeep/jeep-renegade-diesel-longitude-205",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/jeep/jeep-renegade-longitude-205",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/jeep/jeep-renegade-longitude-fwd-2022-319",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/kia/kia-e-niro-204ps-platinum-long-range-153",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/kia/kia-e-soul-platinum-136ps-150",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/kia/kia-ev6-long-range-212",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/kia/kia-picanto-94",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/kia/kia-picanto-optimum-2021-367",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/kia/kia-sportage-lx-automatic-dynamic-203",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/kia/kia-sportage-lx-dynamic-178",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/kia/kia-stonic-optimum-170",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/kia/kia-xceed-lx-optimum-213",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/lexus/lexus-ux250-business-248",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/lexus/lexus-ux250-business-hybrid-248",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/mercedes/mercedes-180-19-42",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/mercedes/mercedes-180-cdi-f1-38",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/metaxeirismena/mercedes-180-cdi-f1-38",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/mercedes/mercedes-a180-d-274",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/mercedes/mercedes-a180-diesel-189",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/mercedes/mercedes-a180d-189",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/mercedes/mercedes-a200-252",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/mercedes/mercedes-b-class-200-290",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/mercedes/mercedes-b180-200",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/mercedes/mercedes-c180-277",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/mercedes/mercedes-cla-180-amg-look-276",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/mercedes/mercedes-cla-180-coupe-amg-291",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/mercedes/mercedes-cla-199",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/mercedes/mercedes-cla-200-amg-look-276",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/mercedes/mercedes-cla-200-coupe-276",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/mercedes/mercedes-cla-200-coupe-amg-291",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/mercedes/mercedes-class-sedan-automatic-198",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/mercedes/mercedes-class-style-334",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/mercedes/mercedes-eqb-250-advanced-311",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/mercedes/mercedes-gla-180d-18-41",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/mercedes/mercedes-gla-200-17-110",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/mercedes/mercedes-gla-200-facelift-19-111",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/mercedes/mercedes-glb-180-style-2020-339",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/mercedes/mercedes-glb-180-style-2020-341",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/mercedes/mercedes-glb-180-style-2022-359",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/mercedes/mercedes-glb-200-207",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/mercedes/mercedes-glb-200-advantage-207",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/mercedes/mercedes-glb-200-amg-look-advantage-318",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/mercedes/mercedes-glb-250-amg-look-245",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/mercedes/mercedes-glc-200-4matic-amg-246",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/mercedes/mercedes-glc-200d-coupe-2022-343",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/mercedes/mercedes-glc-220-d-4matic-coupe-2022-358",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/mercedes/mercedes-sedan-13-198",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/mercedes/smart-cabrio-19-120",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/metaxeirismena/smart-cabrio-19-120",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/mercedes/smart-fortwo-120",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/metaxeirismena/alfa-romeo-gioulietta-18-30",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/metaxeirismena/audi-q3-advanced-35-tfsi-hybrid-s-tronic-77",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/metaxeirismena/bmw-116d-executive-15",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/metaxeirismena/caddy-maxi-van-tdi-100",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/metaxeirismena/citroen-c1-touch-18-99",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/metaxeirismena/fiat-500-pop-19-71",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/metaxeirismena/fiat-500-pop-19-73",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/metaxeirismena/fiat-500x-17-108",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/metaxeirismena/fiat-panda-easy-19-19",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/metaxeirismena/hyundai-i10-active-19-72",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/metaxeirismena/hyundai-i20-l-17-39",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/metaxeirismena/jeep-renegade-4x4-2",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/metaxeirismena/kali-protaprilia-101",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/metaxeirismena/mercedes-180-19-42",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/metaxeirismena/mini-cooper",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/metaxeirismena/mini-countryman-auto-17-107",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/metaxeirismena/nissan-micra-18-86",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/metaxeirismena/nissan-micra-18-neo-montelo-65",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/metaxeirismena/nissan-qashqai-techna-auto-17-105",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/metaxeirismena/peugeot-2008-18-49",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/metaxeirismena/peugeot-2008-diesel-neo-montelo-103",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/metaxeirismena/peugeot-208-active-neo-montelo-102",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/metaxeirismena/suzuki-baleno-19-134",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/metaxeirismena/toyota-yaris-live-20-69",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/metaxeirismena/vw-polo-comfortline-19-60",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/metaxeirismena/vw-polo-18-56",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/metaxeirismena/vw-polo-dsg-comfortline-20-60",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/metaxeirismena/vw-tiguan-r-line-20-76",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/metaxeirismena/xoris-prokatavoli",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/mini-cooper/mini-countryman-auto-17-107",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/mini-cooper/mini-countryman-design-2022-328",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/nissan/nissan-juke-17-66",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/nissan/nissan-juke-acenta-147",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/nissan/nissan-juke-acenta-2022-346",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/nissan/nissan-juke-n-connecta-351",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/nissan/nissan-micra-17-62",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/nissan/nissan-micra-18-63",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/nissan/nissan-micra-18-86",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/nissan/nissan-micra-18-neo-montelo-61",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/nissan/nissan-micra-18-neo-montelo-65",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/nissan/nissan-micra-techna-2022-349",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/nissan/nissan-pulsar-17-67",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/nissan/nissan-pulsar-18-52",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/nissan/nissan-qashqai-acenta-c-look-105",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/nissan/nissan-qashqai-n-connecta-2022-mild-hybrid-337",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/nissan/nissan-qashqai-premium-2022-mild-hybrid-336",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/nissan/nissan-qashqai-techna-2022-mild-hybrid-335",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/nissan/nissan-qashqai-techna-2022-mild-hybrid-338",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/opel/opel-combo-cargo-business-xl-204",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/opel/opel-corsa-95",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/opel/opel-corsa-auto-19-132",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/opel/opel-corsa-design-and-tech-243",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/opel/opel-corsa-design-tech-243",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/opel/opel-corsa-design-tech-turbo-ss-at8-243",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/opel/opel-corsa-edition-12-mt5-ss-242",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/opel/opel-corsa-edition-242",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/opel/opel-corsa-elegance-12-mt6-ss-turbo-243",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/opel/opel-corsa-elegance-287",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/opel/opel-corsa-elegance-turbo-287",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/opel/opel-corsa-vauxhall-2019-369",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/opel/opel-crossland-12-201",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/opel/opel-crossland-diesel-elegance-225",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/volkswagen/kamiq",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/opel/opel-mokka",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/mercedes/fiat",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/mercedes/alfa-romeo",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/mercedes/citroen",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/mercedes/nissan",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/mercedes/peugeot",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/mercedes/suzuki",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/citroen/berlingo-live",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/opel/opel-crossland-elegance-2022-201",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/opel/opel-crossland-elegance-mt6-225",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/opel/opel-crossland-elegance-ss-mt6-225",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/opel/opel-crossland-manual-diesel-225",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/opel/opel-grandland-gs-line-273",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/opel/opel-mokka-diesel-236",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/opel/opel-mokka-elegance-223",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/opel/opel-mokka-gs-line-bev-214",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/opel/opel-mokka-pure-236",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/opel/opel-mokka-pure-turbo-305",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/opel/peugeot-308-12-pure-tech-130-ss-eat8-233",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/peugeot/11-doro-kali-protaprilia-146",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/peugeot/citroen-c1-touch-18-99",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/peugeot/new-peugeot-3008-104",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/peugeot/new-peugeot-3008-diesel-104",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/peugeot/peugeot-108-19-32",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/metaxeirismena/peugeot-108-19-32",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/peugeot/peugeot-108-2021-362",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/peugeot/peugeot-108-32",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/peugeot/peugeot-2008-18-47",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/peugeot/peugeot-2008-19-48",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/peugeot/peugeot-2008-19-49",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/peugeot/peugeot-2008-active-103",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/peugeot/peugeot-208-18-6",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/metaxeirismena/peugeot-208-18-6",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/peugeot/peugeot-208-19-116",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/peugeot/peugeot-208-20-135",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/peugeot/peugeot-208-254",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/peugeot/peugeot-208-active-102",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/peugeot/peugeot-208-active-plus-102",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/peugeot/peugeot-208-active-plus-neo-montelo-102",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/peugeot/peugeot-208-allure-293",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/peugeot/peugeot-208-auto-135",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/peugeot/peugeot-208-business-diesel-19-82",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/metaxeirismena/peugeot-208-business-diesel-19-82",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/peugeot/peugeot-208-business-diesel-19-83",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/peugeot/peugeot-208-business-diesel-19-84",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/peugeot/peugeot-208-business-diesel-19-85",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/peugeot/peugeot-208-diesel-19-97",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/metaxeirismena/peugeot-208-diesel-19-97",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/peugeot/peugeot-3008-12-puretech-130-eat8-active-plus-281",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/peugeot/peugeot-3008-active-plus-179",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/peugeot/peugeot-3008-blue-hdi-active-plus-286",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/peugeot/peugeot-3008-blue-hdi-eat-8-active-plus-286",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/peugeot/peugeot-3008-bluehdi-active-plus-2022-282",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/peugeot/peugeot-3008-diesel-active-plus-282",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/peugeot/peugeot-3008-fl-bluehdi-130-ss-active-plus-282",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/peugeot/peugeot-3008-fl-bluehdi-active-plus-282",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/peugeot/peugeot-3008-gt-line-217",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/peugeot/peugeot-3008-hybrid-gt-line-231",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/peugeot/peugeot-3008-plug-hybrid-allure-231",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/peugeot/peugeot-3008-plug-hybrid-gt-line-231",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/peugeot/peugeot-3008-puretech-active-plus-179",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/peugeot/peugeot-3008-puretech-active-plus-2022-179",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/peugeot/peugeot-3008-puretech-active-plus-2022-281",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/peugeot/peugeot-308-12-pure-tech-130-ss-eat8-233",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/peugeot/peugeot-308-active-20-136",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/peugeot/peugeot-308-active-plus-247",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/peugeot/peugeot-308-allure-plus-hybrid-253",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/peugeot/peugeot-308-pure-tech-233",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/peugeot/renault-clio-17-57",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/peugeot/renault-clio-19-96",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/range-rover",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/range-rover/land-rover-range-rover-evoque-r-dynamic-s-317",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/range-rover/range-rover-evoque-269",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/range-rover/range-rover-evoque-333",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/renault/renault-arkana-edc-dynamic-hybrid-350",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/renault/renault-arkana-tce-2022-ybridiko-314",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/renault/renault-arkana-tce-expression-2022-ybridiko-314",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/renault/renault-captur-dynamic-268",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/renault/renault-captur-expression-267",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/renault/renault-clio-19-96",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/metaxeirismena/renault-clio-19-96",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/renault/renault-clio-equilibre-2022-345",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/renault/renault-clio-techno-332",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/renault/renault-express-van-pack-2022-361",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/seat/cupra-formentor-185",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/metaxeirismena/seat-arona-17-29",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/seat/neo-seat-arona-155",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/seat/neo-seat-arona-fysiko-aerio-156",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/seat/neo-seat-ibiza-154",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/seat/neo-seat-ibiza-fysiko-aerio-157",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/seat/seat-arona-155",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/seat/seat-arona-eco-tsi-style-2022-320",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/seat/seat-arona-tgi-cng-style-2022-321",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/seat/seat-arona-tsi-dsg-style-2022-300",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/seat/seat-ibiza-154",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/seat/seat-ibiza-business-154",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/seat/seat-ibiza-referance-154",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/segway/segway-e110se-escooter-152",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/skoda/skoda-citigo-19-115",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/skoda/skoda-citigoe-iv-electric-88",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/metaxeirismena/skoda-fabia-17-33",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/skoda/skoda-fabia-diesel-18-33",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/skoda/skoda-kamiq-ambition-121",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/skoda/skoda-kamiq-ambition-2022-304",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/skoda/skoda-kamiq-ambition-tsi-2022-312",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/skoda/skoda-karoq-ambition-2022-303",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/skoda/skoda-karoq-tdi-ambition-78",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/metaxeirismena/skoda-karoq-tdi-ambition-78",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/skoda/skoda-scala-ambition-19-59",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/subaru/subaru-xv-4x4-177",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/suzuki/kali-protaprilia-101",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/suzuki/suzuki-baleno-19-134",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/suzuki/suzuki-swift-19-133",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/sym/sym-xpro-125-cargo-174",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/sym",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/scooters/sym",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/sym/sym-xpro-125-cargo-230",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/tesla/tesla-model-3-long-range-173",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/tesla/tesla-model-3-performance-163",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/tesla/tesla-model-3-performance-513hp-163",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/tesla/tesla-model-3-standard-224",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/tesla/tesla-model-3-standard-range-224",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/toyota/lexus-ux250h-navi-19-113",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/toyota/neo-toyota-yaris-hybrid-68",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/toyota/toyota-aygo-x-comfort-2022-316",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/toyota/toyota-aygo-xcity-34",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/toyota/toyota-aygo-xplay-touch-19-34",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/metaxeirismena/toyota-aygo-xplay-touch-19-34",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/toyota/toyota-c-hr-hybrid-e-cvt-20-112",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/toyota/toyota-cool-live-20-69",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/toyota/toyota-yaris-active-19-68",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/toyota/toyota-yaris-van-tts-114",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/volkswagen/audi-a3-sb-tsi-20-64",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/volkswagen/neo-vw-golf-hybrid-auto-87",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/volkswagen/polo-tsi-life-202",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/volkswagen/skoda-kamiq-ambition-121",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/volkswagen/t-roc-10-110ps-life-265",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/volkswagen/t-roc-10-tsi-110ps-life-244",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/volkswagen/t-roc-15-tsi-150ps-dsg-life-265",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/volkswagen/t-roc-15-tsi-150ps-life-265",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/volkswagen/volkswagen-caddy-100",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/volkswagen/caddy-maxi-van-tdi-100",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/volkswagen/volkswagen-t-cross-117",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/volkswagen/volkswagen-t-cross-life-117",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/volkswagen/volkswagen-t-cross-style-347",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/volkswagen/volkswagen-t-roc-advance-2020-70",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/volkswagen/volkswagen-taigo-life-348",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/metaxeirismena/new-vw-19-58",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/volkswagen/vw-move-19-58",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/volkswagen/vw-polo-18-55",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/volkswagen/vw-polo-56",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/volkswagen/vw-polo-comfortline-60",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/volkswagen/vw-polo-dsg-comfortline-20-60",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/volkswagen/vw-t-cross-life-219",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/volkswagen/vw-t-roc-advance-20-70",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/volkswagen/vw-t-roc-advance-70",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/volkswagen/vw-tiguan-15-tsi-150ps-act-evo-dsg-life-264",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/volkswagen/vw-tiguan-r-line-20-76",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/volvo/volvo-xc40-t2-auto-core-325",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/zhidou/zhidou-d2-365",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/zhidou/zhidou-d2-airbag-364",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/zhidou/zhidou-zda520-368",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/seat/seat-mo-escooter-149",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/ebikes/amiti-e-2-272",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/ebikes/ducati-e-scrambler-138",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/ebikes/ducati-e-scrambler-folding-192",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/ebikes/giant-explore-e3sta-145",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/instaride/giant-explore-e3sta-145",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/ebikes/gocycle-gx-144",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/ktm",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/instaride/ktm/macina-cross-p150-street",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/ebikes/ktm-macina-cross-p510-street-140",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/instaride/ktm-macina-fun-a510-193",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/ebikes/ktm-macina-sport-p510-141",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/ebikes/ktm-macina-tour-194",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/ebikes/lacros-scramper-s400-143",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/instaride/lacros-scramper-s400-143",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/ebikes/lacros-trotter-t-400-142",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/mate",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/ebikes/mate-city-383",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/ebikes/moustache-friday-283-195",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/ebikes/niu-gts-251",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/ebikes/niu-gts-cargo-250",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/ebikes/rad-power-bikes-radcity-382",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/instaride/rad-power-bikes-radcity-382",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/ebikes/rad-power-bikes-radmission-380",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/ebikes/rad-power-bikes-radrunner-381",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/ebikes/seat-mo-158",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/ebikes/segway-e110se-151",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/ebikes/segway-ninebot-max-g30-384",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/instaride/ducati-e-scrambler-138",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/instaride/lacros-trotter-t-400-142",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/instaride/niu-gts-251",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/instaride/sym-xpro-125-cargo-197",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/instaride/ducati-e-scrambler-folding-192",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/instaride/rad-power-bikes/radrhino5",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/instaride/rad-power-bikes/radrhino6",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/instaride/seat-mo-158",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/ebikes",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/ebikes",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/segway",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/ducati",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/specialized/turbo-vado",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/instaride/rad-power-bikes/radrhino-6",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/rad-power-bikes/radrhino-6-plus",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/rad-power-bikes/radmini-4",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/instaride/rad-power-bikes/radmini",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/instaride/rad-power-bikes/radmini4",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/rad-power-bikes/radrhino-5",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/alfa-romeo/tonale%3FskuId=IC00218",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/alfa-romeo/tonale%3FskuId=IC00235",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/audi/a3%3FskuId=IC00148",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/audi/q2%3FskuId=IC00038",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/audi/q3%3FskuId=IC00137",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/audi/q3%3FskuId=IC00387",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/bmw/x1%3FskuId=IC00217",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/bmw/x1%3FskuId=IC00427",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/bmw/x2%3FskuId=IC00129",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/byd/atto-3%3FskuId=IC00529",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/byd/seal%3FskuId=IC00530",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/citroen/ami%3FskuId=IC00018",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/citroen/c3%3FskuId=IC00122",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/citroen/c3%3FskuId=IC00511",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/citroen/c4-x%3FskuId=IC00433",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/citroen/c4-x%3FskuId=IC00434",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/citroen/jumper%3FskuId=IC00490",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/citroen/jumper%3FskuId=IC00491",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/dacia/duster%3FskuId=IC00156",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/dacia/duster%3FskuId=IC00325",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/dacia/duster%3FskuId=IC00355",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/dacia/sandero%3FskuId=IC00439",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/ds/ds3-crossback%3FskuId=IC00008",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/ds/ds3-crossback%3FskuId=IC00019",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/fiat/500%3FskuId=IC00060",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/fiat/500%3FskuId=IC00070",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/fiat/500%3FskuId=IC00220",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/fiat/500%3FskuId=IC00221",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/fiat/500%3FskuId=IC00229",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/fiat/500%3FskuId=IC00419",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/fiat/500e%3FskuId=IC00216",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/fiat/500%3FskuId=IC00424",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/fiat/500e%3FskuId=IC00425",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/fiat/panda%3FskuId=IC00207",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/fiat/panda%3FskuId=IC00253",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/fiat/panda%3FskuId=IC00380",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/fiat/panda%3FskuId=IC00501",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/fiat/panda-van%3FskuId=IC00017",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/fiat/panda-van%3FskuId=IC00498",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/ford/fiesta%3FskuId=IC00328",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/ford/fiesta%3FskuId=IC00329",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/ford/focus%3FskuId=IC00333",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/ford/kuga%3FskuId=IC00115",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/ford/ranger%3FskuId=IC00509",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/ford/transit-courier%3FskuId=IC00335",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/hyundai/bayon%3FskuId=IC00123",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/hyundai/bayon%3FskuId=IC00144",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/hyundai/i20%3FskuId=IC00009",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/hyundai/i30%3FskuId=IC00140",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/hyundai/kona%3FskuId=IC00048",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/hyundai/kona%3FskuId=IC00449",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/jaguar/e-pace%3FskuId=IC00369",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/jeep/compass%3FskuId=IC00191",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/jeep/compass%3FskuId=IC00385",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/jeep/renegade%3FskuId=IC00119",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/jeep/renegade%3FskuId=IC00365",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/kia/stonic%3FskuId=IC00046",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/land-rover/range-rover-evoque%3FskuId=IC00465",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/mercedes/a-class%3FskuId=IC00021",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/mercedes/a-class%3FskuId=IC00285",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/mercedes/a-class%3FskuId=IC00322",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/mercedes/a-class%3FskuId=IC00384",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/mercedes/b-class%3FskuId=IC00132",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/mercedes/eqb%3FskuId=IC00223",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/mercedes/gla%3FskuId=IC00157",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/mercedes/gla%3FskuId=IC00415",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/mercedes/glb%3FskuId=IC00126",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/mercedes/glb%3FskuId=IC00345",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/mercedes/glc%3FskuId=IC00347",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/mercedes/glc%3FskuId=IC00396",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/mercedes/glc%3FskuId=IC00397",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/mercedes/vito%3FskuId=IC00519",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/mg/zs%3FskuId=IC00442",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/mg/zs%3FskuId=IC00443",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/nissan/juke%3FskuId=IC00374",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/nissan/qashqai%3FskuId=IC00340",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/nissan/qashqai%3FskuId=IC00341",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/opel/corsa%3FskuId=IC00010",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/opel/corsa%3FskuId=IC00011",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/opel/corsa%3FskuId=IC00015",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/opel/corsa%3FskuId=IC00092",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/opel/corsa%3FskuId=IC00120",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/opel/corsa%3FskuId=IC00487",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/opel/corsa%3FskuId=IC00488",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/opel/crossland%3FskuId=IC00016",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/opel/crossland%3FskuId=IC00152",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/opel/mokka%3FskuId=IC00172",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/peugeot/2008%3FskuId=IC00474",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/peugeot/208%3FskuId=IC00101",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/peugeot/208%3FskuId=IC00102",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/peugeot/208%3FskuId=IC00139",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/peugeot/partner%3FskuId=IC00489",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/renault/arkana%3FskuId=IC00399",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/renault/captur%3FskuId=IC00014",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/renault/clio%3FskuId=IC00098",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/renault/clio%3FskuId=IC00318",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/renault/clio%3FskuId=IC00405",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/renault/clio%3FskuId=IC00441",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/renault/clio%3FskuId=IC00471",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/renault/express-van%3FskuId=IC00316",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/seat/arona%3FskuId=IC00082",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/seat/arona%3FskuId=IC00085",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/seat/arona%3FskuId=IC00086",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/seat/arona%3FskuId=IC00169",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/seat/arona%3FskuId=IC00170",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/seat/ibiza%3FskuId=IC00067",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/seres/5%3FskuId=IC00478",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/skoda/fabia%3FskuId=IC00504",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/skoda/fabia%3FskuId=IC00508",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/toyota/chr%3FskuId=IC00493",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/toyota/rav4%3FskuId=IC00496",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/volkswagen/amarok%3FskuId=IC00444",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/volkswagen/polo%3FskuId=IC00040",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/volkswagen/polo%3FskuId=IC00047",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/volkswagen/t-cross%3FskuId=IC00112",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/volkswagen/t-roc%3FskuId=IC00404",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/volkswagen/taigo%3FskuId=IC00350",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/volkswagen/taigo%3FskuId=IC00409",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/volvo/xc40%3FskuId=IC00455",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/xev/yoyo%3FskuId=IC00451",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/xev/yoyo%3FskuId=IC00452",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/xev/yoyo%3FskuId=IC00453",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/peugeot/3008%3FskuId=IC00003",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/jeep/wrangler%3FskuId=IC00363",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/skoda/kamiq%3FskuId=IC00510",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/skoda/citigo%3FskuId=IC00097",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/peugeot/2008%3FskuId=IC00506",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/opel/corsa%3FskuId=IC00171",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/lexus/ux%3FskuId=IC00088",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/citroen/c3%3FskuId=IC00517",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/mini/countryman%3FskuId=IC00241",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/mercedes/glc%3FskuId=IC00513",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/citroen/berlingo-van%3FskuId=IC00121",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/fiat/panda%3FskuId=IC00219",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/peugeot/208%3FskuId=IC00180",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/volvo/xc40%3FskuId=IC00161",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/citroen/c3%3FskuId=IC00357",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/opel/corsa%3FskuId=IC00230",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/seat/ibiza%3FskuId=IC00167",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/seat/arona%3FskuId=IC00145",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/toyota/aygo-x%3FskuId=IC00401",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/citroen/c3%3FskuId=IC00150",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/jeep/renegade%3FskuId=IC00106",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/peugeot/3008%3FskuId=IC00002",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/jeep/renegade%3FskuId=IC00366",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/ford/puma%3FskuId=IC00331",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/opel/mokka%3FskuId=IC00034",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/suzuki/vitara%3FskuId=IC00528",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/toyota/aygo-x%3FskuId=IC00402",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/mercedes/glb%3FskuId=IC00394",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/ford/transit-connect%3FskuId=IC00336",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/mercedes/a-class%3FskuId=IC00159",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/opel/mokka%3FskuId=IC00036",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/ds/ds7-crossback%3FskuId=IC00020",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/peugeot/208%3FskuId=IC00239",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/toyota/aygo-x%3FskuId=IC00533",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/jaguar/e-pace%3FskuId=IC00370",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/peugeot/208%3FskuId=IC00486",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/suzuki/ignis%3FskuId=IC00520",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/dsfk",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/dsfk/e5",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/alfa-romeo",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/audi",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/bmw",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/citroen",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/dacia",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/fiat",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/ford",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/hyundai",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/jaguar",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/jee",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/kia",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/lacros",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/mate",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/mercedes",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/mini",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/nissan",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/opel",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/peugeot",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/rad-power-bikes",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/renault",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/seat",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/segway-ninebot",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/skoda",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/subaru",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/toyota",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/vehicles/audi/a1",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/vehicles/audi/a3",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/vehicles/audi/q5",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/vehicles/bmw",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/vehicles/bmw/x1",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/vehicles/bmw/x2",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/vehicles/carver",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/vehicles/carver/mr1",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/vehicles/citroen/ami",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/vehicles/citroen/berlingo-van",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/vehicles/citroen/c1",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/vehicles/citroen/c3",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/vehicles/cupra",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/vehicles/cupra/formentor",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/vehicles/dacia",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/vehicles/dacia/dokker",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/vehicles/dacia/duster",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/vehicles/dacia/jogger",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/vehicles/dacia/sandero",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/vehicles/ds",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/vehicles/ds/ds3-crossback",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/vehicles/ford/kuga",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/vehicles/ford/transit-connect",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/vehicles/hyundai/bayon",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/vehicles/hyundai/i10",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/vehicles/hyundai/kona",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/vehicles/jeep/wrangler",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/vehicles/kia",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/vehicles/kia/ev6",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/vehicles/kia/new-sportage",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/vehicles/kia/picanto",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/vehicles/kia/stonic",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/vehicles/kia/xceed",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/vehicles/land-rover",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/vehicles/land-rover/range-rover-evoque",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/vehicles/lexus",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/vehicles/lexus/ux",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/vehicles/mercedes/eqb",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/vehicles/mini",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/vehicles/mini/countryman",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/vehicles/opel/astra",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/vehicles/opel/combo",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/vehicles/opel/crossland",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/vehicles/opel/grandland",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/vehicles/opel/mokka",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/vehicles/peugeot/108",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/vehicles/peugeot/2008",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/vehicles/peugeot/308",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/vehicles/renault/captur",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/vehicles/renault/express-van",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/vehicles/seat/ibiza",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/vehicles/seat/mii",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/vehicles/skoda/citigo",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/vehicles/skoda/karoq",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/vehicles/smart",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/vehicles/smart/fortwo",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/vehicles/subaru",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/vehicles/subaru/xv",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/vehicles/tesla",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/vehicles/tesla/model-3",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/vehicles/toyota",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/vehicles/toyota/auris",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/vehicles/toyota/aygo",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/vehicles/toyota/yaris",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/vehicles/volkswagen/golf",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/vehicles/volkswagen/t-cross",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/vehicles/volkswagen/t-roc",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/vehicles/volkswagen/taigo",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/vehicles/volkswagen/tiguan",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/vehicles/xev",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/vehicles/xev/yoyo",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/vehicles/zhidou",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/vehicles/zhidou/d2",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/vehicles/zhidou/zda520",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/volkswagen",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/xev",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/zhidou",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/vehicles/fiat/panda",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/vehicles/mercedes/glb",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/vehicles/hyundai/i20",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/ebikes/rad-power-bikes/radcity",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/ebikes/rad-power-bikes/radrunner",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/ebikes/segway-ninebot",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/blueground/ebikes/segway-ninebot/max-g30",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/buy/bmw/ix1",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/en/buy/bmw/ix1",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/vehicles",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/peugeot/peugeot-,308-active-plus-247",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/opel/peugeot-,308-12-pure-tech-130-ss-eat8-233",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/en/leasing/mataxerismena",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/en/metaxeirismena",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/instaride/ktm/macina-cross-150",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/instaride/lacros/scramper-s400",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/alfaromeo",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/gr/leasing/citroen/c3",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/ebikes/seat/mo",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/en/leasing/cars/hybrid-electric",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/en/leasing/cars/electirc-hybrid",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/metaxeirismena/citroen-c4-cactus-18-106",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/metaxeirismena%20FIAT%20500",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/kia/kia-picanto-21-94",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/mercedes/alfa-romeo,3",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/fiat/fiat-panda-street-2022-,308",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/epaggelmatika",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/cars",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/metaxeirismena/en",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/metaxeirismena/fusiko-aerio",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/metaxeirismena/instaride",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/metaxeirismena/renault-clio-tce-19-96",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/mini/skuId=IC00500",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/peugeot/peugeot-,308-12-pure-tech-130-ss-eat8-233",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/peugeot/peugeot-,308-active-20-136",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/peugeot/peugeot-,308-allure-plus-hybrid-253",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/leasing/peugeot/peugeot-,308-pure-tech-233",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/sitemap.xml",
    component: component_45stubGZr1UkTkg3
  }
]